<template>
  <div>
    <div class="air__utils__heading">
      <h5>Nombre de Almacén:{{' '+name}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <div class='card'>
      <a-row class="mt-4" style="min-height: 180px">
        <a-tabs type="card" defaultActiveKey="1">
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="ordered-list" />
              Niveles
            </span>
            <wh-levels :warehouse-id="id"/>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">
              <a-icon type="appstore" />
              Posiciones
            </span>
            <wh-positions :warehouse-id="id"/>
          </a-tab-pane>
        </a-tabs>
        <!-- <a-tabs defaultActiveKey="1" type="card" size="large">
        </a-tabs> -->
      </a-row>
    </div>
  </div>
</template>

<script>
import whLevels from './catalogs/whLevels.vue'
import whPositions from './catalogs/whPositions.vue'
// import { notification, Modal } from 'ant-design-vue'
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'whLevelsPos',
  components: {
    whLevels,
    whPositions,
  },
  mixins: [fractalMixin],
  props: {
    warehouse: {
      type: [Number],
      required: false,
    },
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          aIcon: '',
          name: 'operations',
        },
        {
          breadcrumbName: 'Menú de Almacén',
          aIcon: '',
          name: 'warehouse_menu',
        },
        {
          breadcrumbName: 'Catálogos de Almacenes',
          aIcon: '',
          name: 'warehousesCatalogs',
        },
        {
          breadcrumbName: 'Niveles y Posiciones',
          aIcon: '',
        },
      ],
      // Id from the order
      id: this.$route.params.id,
      name: this.$route.params.name,
    }
  },
  async mounted() {
  },
}
</script>
